import { BREAKPOINTS, convertPxToNumber } from '@/components/ds/breakpoints'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { FragmentComponent } from '@/types/graphql'
import { Image } from 'react-datocms'
import { styled } from 'styled-components'
import { HomepageImageBentoGridRecord } from 'types'

const fragment = `
  fragment HomepageImageBentoGridFragment on HomepageImageBentoGridRecord {
    numberOfColumns
    numberOfRows
    content {
      __typename
      columnSpan
      rowSpan
      image {
        ${responsiveImageBlock()}
      }
    }
  }
`

export const StyledBentoGrid = styled.div<{ $gridCols: number; $gridRows: number }>`
  display: grid;
  gap: 16px;
  ${({ $gridCols }) => `grid-template-columns: repeat(${$gridCols}, 1fr)`};
  ${({ $gridRows }) => `grid-template-rows: repeat(${$gridRows}, 1fr)`};
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 1600px) {
    /* maintain aspect ratio */
    height: calc(100vw / 2.28);
  }
`

const StyledBentoCard = styled.div<{ $col: number; $row: number }>`
  ${({ $col }) => `grid-column: span ${$col}`};
  ${({ $row }) => `grid-row: span ${$row}`};
  border-radius: var(--grid-16);
  overflow: hidden;
  position: relative;
`

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const HomepageImageBentoGrid: FragmentComponent<{}, HomepageImageBentoGridRecord> = ({ record }) => {
  const { width } = useWindowSize()

  return (width ?? 0) > convertPxToNumber(BREAKPOINTS.m) ? (
    <StyledBentoGrid $gridCols={record.numberOfColumns} $gridRows={record.numberOfRows}>
      {record.content.map(({ image, columnSpan, rowSpan }, index) => (
        <StyledBentoCard key={index} $col={columnSpan} $row={rowSpan}>
          {/* Max width doesn't seem to apply when used in the styled component */}
          <StyledImage data={image.responsiveImage} style={{ maxWidth: 'none' }} />
        </StyledBentoCard>
      ))}
    </StyledBentoGrid>
  ) : null
}

HomepageImageBentoGrid.fragment = fragment
HomepageImageBentoGrid.recordName = 'HomepageImageBentoGridRecord'
