import { Section } from '@/lib/gtm/typesV2'
import { useContext, useEffect } from 'react'
import { TrackingContext } from '@/context/trackingContext'

export const useSetTrackingSection = (section: Section) => {
  const { setSection } = useContext(TrackingContext)

  useEffect(() => {
    setSection(section)
    return () => {
      setSection(null)
    }
  }, [setSection, section])
}
