import { DSSpacer, DSText } from '@zoe/ds-web'
import { DSLinkText } from '@/components/ds/LinkText/LinkText'
import { trackFooterClick } from './Footer'
import { useRouter } from 'next/router'
import styled from 'styled-components'

const StyledMenuContainer = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
`

export const FooterCompanylMenu: React.FC = () => {
  const router = useRouter()

  // Check if the current pathname includes 'daily30'
  const showDaily30TermsLink = router.pathname.includes('daily30')

  return (
    <StyledMenuContainer>
      <DSText as="h6" variant="fluid-label-100" weight="lotaSemiBold" colour="primary-inverse">
        Company
      </DSText>
      <DSSpacer size={16} direction="vertical" />
      <ul>
        <li>
          <DSLinkText href="/about-zoe" onPress={() => trackFooterClick('About us')}>
            <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
              About us
            </DSText>
          </DSLinkText>
        </li>
        <DSSpacer size={12} direction="vertical" />
        <li>
          <DSLinkText
            href="https://www.notion.so/joinzoe/Work-at-ZOE-8b8954cb8b50419e8f7d87b9ea176e1f"
            onPress={() => trackFooterClick('Careers')}
          >
            <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
              Careers
            </DSText>
          </DSLinkText>
        </li>
        <DSSpacer size={12} direction="vertical" />
        <li>
          <DSLinkText href="/terms" onPress={() => trackFooterClick('Terms of Service')}>
            <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
              Terms of Service
            </DSText>
          </DSLinkText>
        </li>
        <DSSpacer size={12} direction="vertical" />
        {showDaily30TermsLink && (
          <>
            <li>
              <DSLinkText href="/daily30/terms" onPress={() => trackFooterClick('Terms of Service')}>
                <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
                  Terms of Service (Daily30+)
                </DSText>
              </DSLinkText>
            </li>
            <DSSpacer size={12} direction="vertical" />
          </>
        )}
        <li>
          <DSLinkText href="/privacy-policy" onPress={() => trackFooterClick('Privacy policy')}>
            <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
              Privacy policy
            </DSText>
          </DSLinkText>
        </li>
        <DSSpacer size={12} direction="vertical" />
        <li>
          <DSLinkText href="/cookie-policy" onPress={() => trackFooterClick('Cookie policy')}>
            <DSText as="span" weight="lotaLight" variant="fluid-label-100" colour="primary-inverse">
              Cookie policy
            </DSText>
          </DSLinkText>
        </li>
      </ul>
    </StyledMenuContainer>
  )
}
