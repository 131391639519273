import { LocalisedPageProps } from '@/types/locales'
import Head from 'next/head'
import { useSelectLocalisedPage } from '@/lib/hooks/useSelectLocalisedPage'
import { usePathname } from 'next/navigation'
import { HomePageContent2024 } from '@/components/pages/homepage2024/HomepageContent'
import { Header } from '@/components/elements/header/Header'
import { Footer } from '@/components/elements/new-footer/Footer'
import { HomePage } from '@/types/homepage'
import { useSetTrackingSection } from '@/lib/hooks/useSetTrackingSection'
import { HomepageLoadingSkeleton } from '@/components/pages/homepage2024/content/HomepageLoadingSkeleton'
import { useExperimentContext } from '@/context/ExperimentContext'
import { useEffect } from 'react'

export const Homepage2024 = ({ localisedPages }: LocalisedPageProps) => {
  const pathname = usePathname()
  const { setVariant, variant } = useExperimentContext()
  const page = useSelectLocalisedPage(localisedPages) as HomePage
  useSetTrackingSection('homepage')

  useEffect(() => {
    if (variant === null) {
      setVariant('Image first new homepage')
    }
    return () => {
      setVariant(null)
    }
  }, [setVariant])

  if (!page) {
    return <HomepageLoadingSkeleton />
  }

  return (
    <>
      <Head>
        <title>{page.metadata.title}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={page.metadata.description} />
        <meta property="og:title" content={page.metadata.title} key="title" />
        <meta property="og:description" content={page.metadata.description} key="description" />
        <meta property="og:image" content={page.metadata?.image?.responsiveImage?.src} key="image" />
        <meta property="og:url" content={`${process.env.SITE_URL}${pathname}`} key="url" />
        <meta property="og:type" content="article" key="type" />
        <meta name="twitter:title" content={page.metadata.title} />
        <meta name="twitter:description" content={page.metadata.description} />
        {page.metadata.noIndex && <meta name="robots" content="noindex,nofollow" />}
      </Head>

      <Header ctaText="Join ZOE" />

      <HomePageContent2024 content={page.content} />

      <Footer showPreFooter />
    </>
  )
}
