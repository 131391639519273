import styled from 'styled-components'
import { CentredContentBlock } from '@/blocks/CentredContentBlock'
import { HomePageContentBlocks } from '@/types/homepage'
import { Grid } from '@/components/blocks/DesignSystem/Grid'
import { GridBlock } from '@/components/blocks/DesignSystem/GridBlock'
import { SpacerBlock } from '@/components/blocks/DesignSystem/Spacer'
import { HomepageImageBentoGrid } from './content/HomepageImageBentoGrid'

const StyledPage = styled.div`
  padding-top: var(--grid-72);

  .hero-title {
    font-size: 100px;
    line-height: 105%;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    padding-top: 64px;

    .hero-title {
      font-size: 56px;
      line-height: 105%;
    }
  }
`

const renderBlock = (record, index) => {
  const key = `${record.id}-${index}}`
  switch (record.__typename) {
    case 'CentredContentBlockRecord':
      return <CentredContentBlock record={record} key={key} />
    case 'GridRecord':
      return <Grid key={key} record={record} />
    case 'GridBlockRecord':
      return <GridBlock key={key} record={record} />
    case 'SpacerRecord':
      return <SpacerBlock key={key} record={record} />
    case 'HomepageImageBentoGridRecord':
      return <HomepageImageBentoGrid key={key} record={record} />
  }
}

type HomePageContentProps = {
  content: HomePageContentBlocks[]
}

export const HomePageContent2024 = ({ content }: HomePageContentProps) => {
  return <StyledPage>{(content ?? []).map((record, index) => renderBlock(record, index))}</StyledPage>
}
