import { LocaleSupportedPage, LocalisedPages } from '@/types/locales'
import { useEffect, useState } from 'react'
import { useCheckoutCountry } from '@/lib/hooks/useCheckoutCountry'

export const useSelectLocalisedPage = (localisedPages: LocalisedPages) => {
  const { checkoutCountry, isReady } = useCheckoutCountry()
  const [localisedPage, setLocalisedPage] = useState<LocaleSupportedPage>()

  useEffect(() => {
    if (isReady) {
      const locale = checkoutCountry === 'US' ? 'en_US' : 'en'
      setLocalisedPage(localisedPages[locale])
    }
  }, [checkoutCountry, localisedPages, isReady])

  return localisedPage
}
