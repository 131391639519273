import { DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import { DSIcon } from '@/components/ds/Icon/Icon'
import Link from 'next/link'
import { trackFooterClick } from './Footer'
import styled from 'styled-components'

const StyledMenuContainer = styled.div`
  a {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
`
export const FooterSocialMenu: React.FC = () => (
  <StyledMenuContainer>
    <DSText as="p" variant="fluid-paragraph-200" colour="primary-inverse" weight="lotaLight">
      Follow us
    </DSText>
    <DSSpacer size={12} direction="vertical" />
    <DSFlex direction="row" justifyContent="flex-start" gap={12}>
      <Link
        href="https://www.instagram.com/zoe/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('instagram')}
      >
        <DSIcon name="instagram" size={24} />
      </Link>
      <Link
        href="https://www.facebook.com/joinzoe1/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('facebook')}
      >
        <DSIcon name="facebook" size={24} />
      </Link>
      <Link
        href="https://www.youtube.com/c/ZOE-health"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('youtube')}
      >
        <DSIcon name="youtube" size={24} />
      </Link>
    </DSFlex>
  </StyledMenuContainer>
)
