import { Product } from '../checkout/types'

export const WAITLIST_EMAIL_SUBMITTED = 'waitlist-email-submitted'
export const NUTRITION_NEWSLETTER_EMAIL_SUBMITTED = 'nutrition-newsletter-email-submitted'
export const FREEGUIDE_EMAIL_SUBMITTED = 'freeguide-email-submitted'
export const GUTSHOT_EMAIL_SUBMITTED = 'gutshot-email-submitted'
export const WHITEPAPER_EMAIL_SUBMITTED = 'whitepaper-email-submitted'
export const D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED = 'd30-gb-no-stock-waitlist-email-submitted'
export const D30_NON_GB_WAITLIST_EMAIL_SUBMITTED = 'd30-non-gb-waitlist-email-submitted'

export type BrazeCustomEventName =
  | typeof WAITLIST_EMAIL_SUBMITTED
  | typeof NUTRITION_NEWSLETTER_EMAIL_SUBMITTED
  | typeof FREEGUIDE_EMAIL_SUBMITTED
  | typeof GUTSHOT_EMAIL_SUBMITTED
  | typeof WHITEPAPER_EMAIL_SUBMITTED
  | typeof D30_GB_NO_STOCK_WAITLIST_EMAIL_SUBMITTED
  | typeof D30_NON_GB_WAITLIST_EMAIL_SUBMITTED

export type BrazeRegisterUserAdditionalFields = {
  firstName?: string
  lastName?: string
  country?: string
  quizUserId?: string
}

export type BrazePurchaseProperties = Product[]

export type BrazePurchaseEventDetails = {
  email: string
  quizUserId?: string
  firstName: string
  lastName: string
  orderCountry: string
  plansUrl?: string
  preorder: boolean
  freeTrial: boolean
  orderNumber: string
  productId: string
  price: number
  quantity: number
  currencyCode?: string
  purchaseProperties: BrazePurchaseProperties
}
