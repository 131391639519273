import React from 'react'
import { useContext } from 'react'
import { GeoContext } from '@/context/geoContext'
import { DSText } from '@zoe/ds-web'

const getCompanyNameByCountry = (country: string): string => {
  const companyNames: { [key: string]: string } = {
    US: 'ZOE',
    UK: 'ZOE Limited',
  }
  return companyNames[country] || 'ZOE Limited'
}

export const Copyright: React.FC = () => {
  const { country } = useContext(GeoContext)
  const year = new Date().getFullYear()

  return (
    <DSText as="span" variant="fluid-label-100" colour="primary-inverse">
      &copy;{year} {getCompanyNameByCountry(country)}
    </DSText>
  )
}
