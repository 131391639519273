import { Copyright } from '@/components/elements/new-footer/Copyright'
import { FooterNewsletterSignup } from '@/components/elements/new-footer/FooterNewsletterSignup'
import { DSText } from '@zoe/ds-web'
import React from 'react'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSGrid, DSGridBlock } from '@/components/ds/Grid/Grid'
import { trackCtaClick } from '@/lib/gtm/gtmV2'
import { FooterSocialMenu } from './FooterSocialMenu'
import { FooterCompanylMenu } from './FooterCompanyMenu'
import { FooterResourcesMenu } from './FooterResourcesMenu'
import { DSDivider } from '@/components/ds/Divider/Divider'
import { FooterCards } from './FooterCards'
import { DSHide } from '@/components/ds/Responsive/Hide/Hide'

type FooterProps = {
  showPreFooter?: boolean
}

export const trackFooterClick = (label: string) => {
  trackCtaClick('footer', label, { link_position: 'footer' })
}

export const Footer: React.FC<FooterProps> = ({ showPreFooter }) => {
  return (
    <>
      {showPreFooter && (
        <DSGrid backgroundColor="aubergine-bold" mobileBackgroundColor="aubergine-bold" backgroundFullWidth>
          <DSGridBlock breakConfig={{ m: { order: 1 }, s: { order: 1 } }}>
            <DSSpacer size={144} direction="vertical" breakAt={{ m: 96 }} />
            <DSText as="span" variant="fluid-label-100" colour="primary-inverse">
              EXPLORE ZOE
            </DSText>
            <DSSpacer size={32} direction="vertical" />
            <DSHide below="m">
              <DSDivider variant="secondary" />
              <DSSpacer size={48} direction="vertical" />
            </DSHide>
          </DSGridBlock>

          <DSGridBlock
            breakConfig={{
              xl: { colSpan: 3 },
              l: { colSpan: 3 },
              m: { colSpan: 3, order: 3 },
              s: { colSpan: 2, order: 3 },
            }}
          >
            <DSSpacer size={0} breakAt={{ l: 72 }} direction="vertical" />
            <FooterNewsletterSignup />
          </DSGridBlock>
          <DSGridBlock
            breakConfig={{
              xl: { colSpan: 8, offset: 1 },
              l: { colSpan: 8, offset: 1 },
              m: { colSpan: 6, order: 2 },
              s: { colSpan: 2, order: 2 },
            }}
          >
            <FooterCards />
          </DSGridBlock>
          <DSGridBlock breakConfig={{ m: { order: 4 }, s: { order: 4 } }}>
            <DSHide below="m">
              <DSSpacer size={72} direction="vertical" />
            </DSHide>
            <DSDivider variant="secondary" />
            <DSSpacer size={48} direction="vertical" breakAt={{ m: 96 }} />
          </DSGridBlock>
        </DSGrid>
      )}
      <DSGrid backgroundColor="aubergine-bold" mobileBackgroundColor="aubergine-bold" backgroundFullWidth>
        {!showPreFooter && <DSSpacer size={144} direction="vertical" breakAt={{ m: 96 }} />}
        <DSGridBlock breakConfig={{ xl: { colSpan: 2 }, l: { colSpan: 2 }, m: { colSpan: 6 } }}>
          <FooterSocialMenu />
          <DSSpacer size={0} direction="vertical" breakAt={{ l: 48 }} />
        </DSGridBlock>

        <DSGridBlock
          breakConfig={{
            xl: { colSpan: 2, offset: 2 },
            l: { colSpan: 2, offset: 2 },
            m: { colSpan: 3 },
            s: { colSpan: 2 },
          }}
        >
          <FooterCompanylMenu />
          <DSSpacer size={0} direction="vertical" breakAt={{ m: 32 }} />
        </DSGridBlock>

        <DSGridBlock breakConfig={{ xl: { colSpan: 2 }, l: { colSpan: 2 }, m: { colSpan: 3 }, s: { colSpan: 2 } }}>
          <FooterResourcesMenu />
        </DSGridBlock>
        <DSSpacer size={48} direction="vertical" />
        <DSDivider variant="secondary" />
        <DSSpacer size={16} direction="vertical" />
        <Copyright />
        <DSSpacer size={144} direction="vertical" breakAt={{ m: 96 }} />
      </DSGrid>
    </>
  )
}
