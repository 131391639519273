import React from 'react'
import styled, { css } from 'styled-components'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { StyledBentoGrid } from '@/components/pages/homepage2024/content/HomepageImageBentoGrid'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { DSLoadingSkeleton, DSLoadingSkeletonContainer, loadingAnimation } from '@zoe/ds-web'

const StyledLoadingContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.m}) {
    padding: var(--grid-16);
  }
`

const SkeletonMobileImage = styled(DSLoadingSkeleton)`
  width: 100%;
  max-height: 350px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-self: center;
`

const SkeletonBentoCard = styled.div<{ $col: number; $row: number }>`
  ${({ $col }) => `grid-column: span ${$col}`};
  ${({ $row }) => `grid-row: span ${$row}`};
  border-radius: var(--grid-16);
  overflow: hidden;
  position: relative;
  animation: ${loadingAnimation};
  background-color: #ecebeb;
  display: flex;
  width: 100%;
  height: 100%;
`

export const HomepageLoadingSkeleton = () => {
  const { breakpoint } = useWindowSize()
  const isMobile = breakpoint === 's'
  return (
    <DSLoadingSkeletonContainer ariaLabel={'Loading home page'}>
      <StyledLoadingContainer>
        <DSFlex direction="column" justifyContent="center" alignItems="center">
          <DSSpacer size={144} direction="vertical" breakAt={{ m: 96 }} />
          <DSLoadingSkeleton width={'570px'} height={'90px'} mobile={{ width: '300px', height: '50px' }} />
          <DSSpacer size={24} direction="vertical" breakAt={{ m: 16 }} />
          <DSLoadingSkeleton width={'380px'} height={'90px'} mobile={{ width: '200px', height: '50px' }} />
          <DSSpacer size={24} direction="vertical" breakAt={{ m: 16 }} />
          <DSLoadingSkeleton width={'540px'} height={'20px'} mobile={{ width: '100%' }} />
          <DSSpacer size={32} direction="vertical" />
          <DSLoadingSkeleton width={'190px'} height={'50px'} mobile={{ width: '100%' }} borderSquared={true} />
          <DSSpacer size={48} direction="vertical" breakAt={{ m: 32 }} />
          <DSLoadingSkeleton width={'250px'} height={'20px'} />
          <DSSpacer size={96} direction="vertical" breakAt={{ m: 48 }} />
          {isMobile ? (
            <SkeletonMobileImage width={'100%'} height={'350px'} />
          ) : (
            <StyledBentoGrid $gridCols={7} $gridRows={3} style={{ width: '100vw', height: '50vh' }}>
              <SkeletonBentoCard $col={3} $row={2} />
              <SkeletonBentoCard $col={1} $row={1} />
              <SkeletonBentoCard $col={1} $row={1} />
              <SkeletonBentoCard $col={2} $row={2} />
              <SkeletonBentoCard $col={2} $row={2} />
              <SkeletonBentoCard $col={2} $row={1} />
              <SkeletonBentoCard $col={1} $row={1} />
              <SkeletonBentoCard $col={2} $row={1} />
            </StyledBentoGrid>
          )}
        </DSFlex>
      </StyledLoadingContainer>
    </DSLoadingSkeletonContainer>
  )
}
